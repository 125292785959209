<template>
  <div class="animated fadeIn">
    <b-card no-body>
      <b-card-header>
        <i class="icon-note"></i> Add Permission
        <div class="card-header-actions">
          <a class="card-header-action" href="permissions" rel="noreferrer noopener">
            <small class="text-muted">back</small>
          </a>
        </div>
      </b-card-header>
      <b-card-body>
          <div class="loading" v-if="isLoading == true">
              <div class="sk-three-bounce">
                  <div class="sk-child sk-bounce1"></div>
                  <div class="sk-child sk-bounce2"></div>
                  <div class="sk-child sk-bounce3"></div>
              </div>
          </div>
        <b-row>
          <b-col>
            <!--<h6>Simple Form</h6>-->
            <b-form v-on:submit.prevent="onSubmit" v-on:reset.prevent="onReset" novalidate>
              <b-form-group id="permissionInputGroup3"
                            label="Name"
                            label-for="permission">
                <b-form-input id="permission"
                              type="text"
                              v-model.trim="$v.form.permission.$model"
                              :state="chkState('permission')"
                              aria-describedby="permissionFeedback"
                              placeholder="Name"
                              autocomplete='permission' />
                <b-form-invalid-feedback id="permissionFeedback" >
                  <span v-if="!$v.form.permission.required">- Nama permission tidak boleh kosong!</span>
                  <span v-if="!$v.form.permission.isUnique">- Nama permission tidak boleh sama dengan data sebelumnya!</span>
                </b-form-invalid-feedback>
              </b-form-group>
              <b-row>
                <b-col>
                  <p style="font-weight:bold">Roles</p>
                </b-col>
              </b-row>
              <b-row>
                <b-col sm="4" v-for="(roles, index) in role" :key="index">
                  <b-form-group>
                    <b-form-checkbox-group id="checkbox-group-2" v-model="selected" name="flavour-2">
                      <b-form-checkbox :value="role[index].name">{{ role[index].name }}</b-form-checkbox><br>
                    </b-form-checkbox-group>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-button type="submit" variant="primary">
                Submit
              </b-button>
            </b-form>
            <br/>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate"
import { required } from "vuelidate/lib/validators"



export default {
  name: "ValidationForms",
  data() {
    return {
      form: {
        firstName: "",
        lastName: "",
        permission: "",
        email: "",
        password: "",
        confirmPassword: ""
      },
      role: "",
      roles: [],
      selected: "",
      splitSelect: "",
      itemsPerRow: 1,
      errors: {
        message: [],
        status: [],
        code: '',
        headers: [],
        form: {
          permission: [],
        },
      },
      isLoading: false
    }
  },
  computed: {
    formStr() { return JSON.stringify(this.form, null, 4) },
    isValid() { return !this.$v.form.$anyError },
    isDirty() { return this.$v.form.$anyDirty },
    invCheck() { return 'You must accept before submitting' },
  },
  mixins: [validationMixin],
  validations: {
    form: {
      permission: {
        required,
        isUnique (value) {
          if (value === '') return true

          return new Promise ((resolve, reject) => {
            setTimeout(() => {

              this.$http.post(`permission-name/${value}`)
              .then((success) => {
                if (success.data == "") {
                  resolve(true)
                  return true
                }else{
                  reject(false)
                  return false
                }
              })
              .catch((error) => {
                if (error.response) {
                  this.errors.code = error.response.status;
                  this.errors.status = error.response.data.meta.code;
                  this.errors.headers = error.response.headers;
                }
              })
            }, 350 + Math.random() * 300)
          })
        }
      },
    }
  },
  methods: {
    loadRole() {

        this.$http.get(`role-data`, {
            params: {
                role: this.roles
            }
        }).then((result) => {
        this.role = result.data.role;
        }).catch((error) => {
          if (error.response) {
            this.errors.code = error.response.status;
            this.errors.status = error.response.data.meta.code;
            this.errors.headers = error.response.headers;
          }
        })
    },
    onSubmit() {
    //   if (this.validate()) {
        this.validate()
    //     this.$nextTick(() => {
      this.isLoading = true
            const formData = new FormData();
            formData.append('role', this.selected);
            formData.append('name', this.form.permission);


            this.$http.post(`store-permission`, formData
            ).then(() => {
              this.isLoading = false
              this.$router.push("/permissions");
              this.$toasted.success('Permission successfully created!')
            }).catch((error) => {
              if (error.response) {
                this.isLoading = false
                this.errors.code = error.response.status;
                this.errors.status = error.response.data.meta.code;
                this.errors.headers = error.response.headers;
                if(this.errors.status == 422) {
                  this.$toasted.error('Please fill out the form that must be required')
                  this.errors.message = error.response.data.meta.message;
                  this.errors.form.permission = this.errors.message.permission;
                }
              }
            })
    //     })
    //   }
    },
    onReset() {
      // Reset validation
      this.form = Object.assign({})
      this.submitted = false
      this.$nextTick(() => {
        this.$v.$reset()
        this.feedBack = 'secondary'
      })
    },
    chkState(val) {
      const field = this.$v.form[val]
      return !field.$dirty || !field.$invalid
    },
    findFirstError(component = this) {
      if (component.state === false) {
        if (component.$refs.input) {
          component.$refs.input.focus()
          return true
        }
        if (component.$refs.check) {
          component.$refs.check.focus()
          return true
        }
      }
      let focused = false
      component.$children.some((child) => {
        focused = this.findFirstError(child)
        return focused
      })

      return focused
    },
    validate() {
      this.$v.$touch()
      this.$nextTick(() => this.findFirstError())
      return this.isValid
    }
  },
  mounted () {
      this.loadRole();
  },
}
</script>

<style scoped>
.btn.disabled {
  cursor: auto;
}
</style>
<style>
/* Absolute Center Spinner */
.loading {
  position: fixed;
  z-index: 999;
  height: 2em;
  width: 2em;
  overflow: visible;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

/* Transparent Overlay */
.loading:before {
  content: '';
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.3);
}

</style>
<style src="spinkit/scss/spinkit.scss" lang="scss" />
